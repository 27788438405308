@import '../../assets/css/def/defaults.def.scss';
@import '../../assets/css/def/colors.def.scss';
@import '../../assets/css/def/flex.def.scss';

$tags-input-height: $default-input-height - 2*$default-border-width;

bi-tags {
  @include flex(column);

  .bi-tags-container {
    display: flex !important;
    min-height: 36px !important;
    padding: 5px 5px 0 5px !important;
    background: $white !important;
    height: auto !important;
  }

  .bi-tags-input {
    min-width: 50px !important;
    height: 25px !important;
    margin: 0 5px 5px 0 !important;
    padding: 0 10px !important;
    border: 0 !important;
    background-color: transparent !important;
  }

  .bi-tags-tag {
    max-width: 100%;
    margin: 0 5px 5px 0;
    padding: 0 10px;
    background-color: $grey--200;
    border-radius: 3px;
    height: $default-input-height - 2 * 6px;
    line-height: 24px;

    &:last-of-type {
      margin-right: 0;
    }

    .bi-tags-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .bi-tags-remove {
      margin-right: -4px;
      font-size: 10px;
      font-weight: bold;

      &:hover {
        background-color: darken($grey--200, 5) !important;
      }
    }
  }

  bi-dropdown {
    .bi-dropdown-menu {
      display: block;
      margin-top: -1px;
    }
  }
}
