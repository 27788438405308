@import './colors.def';

$default-font-family: 'Open Sans';
$default-font-size: 12px;
$default-space: 10px;
$default-text-color: $black;
$default-border-color: $grey--200;
$default-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
$default-border-width: 1px;
$default-input-height: 36px;
