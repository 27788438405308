@import '../../lib/ui/assets/css/def/colors.def';

quix-notebook {
  .quix-note-container {
    position: relative;

    &.quix-note-container--editable {
      padding-left: 8px;
    }
  }
}
