@import '../../../ui/assets/css/def/colors.def';

bi-viz-pie {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .bvp-container {
    margin-left: -16px;
    width: 100%
  }

  [bi-maximizable] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .bm--maximized {
    .bvp-container {
      max-height: 70%;
    }
  }
  .modebar-container {
    margin: 2px 30px 0 0 !important;
  }
}