@import './flex.def';
@import './icon.def';
@import './colors.def';

%link {
  @include flex-inline(row, center);

  color: $primary;
  text-decoration: none !important;
  cursor: pointer;


  &:hover {
    color: darken($primary, 8);
  }

  &[target="_blank"] {
    &:after {
      @extend %icon;

      content: 'open_in_new';
      margin-left: .3em;
      font-size: 1em !important;
      text-decoration: none !important;
    }
  }
}