@import './defaults.def';

%table {
  width: 100%;

  > thead > tr > th {
    padding: 8px;
    border-bottom: 2px solid $default-border-color;
    text-align: left;
    vertical-align: bottom;
  }

  > tbody > tr > td {
    padding: 8px;
    border-top: 1px solid $default-border-color;
    text-align: left;
    vertical-align: top;
  }
}