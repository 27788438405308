@import 'colors.def';

%tag {
  display: inline-block;
  padding: 0 8px;
  border: 1px solid currentColor;
  border-radius: 3px;
  font-size: 11px;
  line-height: 20px;
  color: $muted;
}
