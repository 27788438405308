@import '../colors.def';
@import '../header.def';
@import '../border.def';

%app-header {
  @include header(54px, 12px);

  .bi-app-user-logo {
    width: 36px;
    height: 36px;
    border-radius: 100px;
  }

  .bi-app-title {
    @include header-title(20px, 200);

    font-family: bi-ui;

    .bi-app-logo {
      width: 24px;
      margin-right: 8px;
    }
  }
}
