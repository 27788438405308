@import '../../assets/css/def/colors.def';

[bi-maximizable] {
  position: relative;

  .bm-toggle {
    position: absolute;
    top: 0;
    right: 0;

    &.bm-toggle-custom {
      position: unset;
    }
  }

  &.bm--maximized {
    position: fixed;
    right: 5%;
    left: 5%;
    top: 5%;
    bottom: 5%;
    margin: 0 !important;
    padding: 15px;
    background: $white;
    border-radius: 3px;
    z-index: 201;

    + .bm-backdrop {
      display: block;
    }

    .bm-toggle {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 1000;

      &.bm-toggle-custom {
        position: unset;
      }
    }
  }

  & + .bm-backdrop {
    content: '';
    position: fixed;
    display: none;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: $black;
    opacity: .5;
    z-index: 200;
  }
}
