@import './def/colors.def';
@import './def/action.def';
@import './def/button.def';

.bi-theme--lighter {
  .bi-action {
    @include action('lighter');
  }
}

.bi-theme--light {
  .bi-action {
    @include action('light');
  }

  .bi-theme--lighter {
    .bi-action {
      @include action('lighter');
    }
  }
}

.bi-theme--dark {
  .bi-action {
    @include action('dark');
  }
}

.bi-theme--darker {
  .bi-action {
    @include action('darker');
  }
}