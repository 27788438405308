@import '../../../ui/assets/css/def/colors.def';

bi-app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  .bi-app-header {
    [ng-transclude="header"] {
      header {
        display: block;
        height: 54px;
      }
    }

    bi-dropdown {
      bi-toggle {
        height: 53px;
      }
    }
  }

  .bi-app-sidebar {
    width: 300px;

    .bi-app-sidebar-toggle {
      margin-right: -6px;
    }

    .bi-section-content {
      padding: 0;
    }

    .ui-resizable-handle {
      border-right: 1px solid $light-bg;
    }
  }
}
