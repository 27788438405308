@import '../../lib/ui//assets/css/def/colors.def';

quix-note {
  display: flex;
  flex-direction: column;

  .quix-checkbox {
    margin-top: 7px;
  }

  [bf-toggle] {
    width: 100%;
    height: 100%;
  }

  .bm--maximized {
    top: 10px !important;
    right: 10px !important;
    bottom: 10px !important;
    left: 10px !important;

    .bi-panel-header,
    .bi-panel-content {
      padding: 0;
    }

    .bi-panel-header {
      margin-top: -10px;
    }
  }

  .bi-panel-header {
    position: relative;

    .quix-note-drag-indicator {
      position: absolute;
      top: 11px;
      left: -2px;
      margin: 0 !important;

      .bi-action {
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
}
