@import '../../../ui/assets/css/def/colors.def';
@import '../../../ui/assets/css/def/flex.def';

bi-sql-runner {
  @include flex(column);

  width: 100%;
  flex-grow: 1;

  run-hint {
    display: block;
    line-height: 0;

    table {
      tr {
        line-height: 28px;
      }

      th:first-child {
        padding-right: 12px;
        text-align: right;
      }

      th:last-child {
        text-align: left;
      }

      .bi-tag--sm {
        color: $white;
      }
    }
  }
}
