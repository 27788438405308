@import '../../lib/ui//assets/css/def/colors.def';

quix-actions {
  .quix-actions-reverse {
    flex-direction: row-reverse;

    > * {
      margin-right: 0 !important;

      &:not(:first-child) {
        margin-right: 15px !important;
      }
    }

    &.quix-actions-compact {
      > * {
        &:not(:first-child) {
          margin-right: 10px !important;
        }
      }
    }
  }

  bi-dropdown {
    .bd-content {
      margin-top: 5px;
    }
  }
}
