@import './lib/ui/assets/css/def/colors.def';
@import './lib/ui/assets/css/def/defaults.def';
@import './lib/ui/assets/css/def/icon.def';

body {
  background-color: $light-bg;
}

bi-app {
  $splashWidth: 150px;
  $splashHeight: 208px;

  >.quix-splash {
    position: absolute;
    width: $splashWidth;
    height: $splashHeight;
    top: 50%;
    left: 50%;
    margin: (-$splashHeight / 2) 0 0 (-$splashWidth / 2);
  }

  .quix-splash-logo {
    width: $splashWidth;
    filter: drop-shadow(2px 2px 5px $grey--400);
  }
}

.quix-content {
  box-shadow: inset 0 1px 6px rgba(0, 0, 0, .05);
}

.quix-user-avatar {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}

.quix-checkbox {
  display: block;
  line-height: 0;

  .bi-action {
    width: 30px;
    overflow: hidden;
  }

  i {
    transition: color, .1s;
    color: $grey--300 !important;
    font-size: 23px;

    &:not([disabled]) {
      &:hover,
      &.checked {
        color: $muted !important;
      }
    }
  }
}

.quix-popup {
  display: none !important;

  &.bm--maximized {
    display: flex !important;
  }

  &.quix-search-popup {
    top: 54px !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    padding: 0 !important;
    border-radius: 0 !important;

    & + .bm-backdrop {
      display: none !important;
    }

    .bm-toggle {
      display: none !important;
    }
  }
}

.quix-temp-note-button {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: flex;
  width: 60px !important;
  height: 60px !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  border-radius: 50% !important;
  cursor: pointer;
  z-index: 100;

  &:before {
    @extend %icon;

    content: 'code';
    font-size: 28px;
    color: white;
  }
}

.bi-section {
  .quix-empty-state {
    margin-top: -100px;
  }
}

.bi-table-container.bi-table--nav,
bi-tbl.bi-table--nav {
  td {
    [contenteditable="false"] {
      padding: 0 !important;
      border: 0 !important;
    }

    [contenteditable="true"] {
      position: relative;
      top: 3px;
      padding-top: 0 !important;
    }
  }

  .mark-column {
    width: 42px;
    padding: 0;

    .quix-checkbox {
      width: 30px;
      margin: 6px;
    }
  }

  .name-column {
    padding-left: 0;
  }
}

.bi-toast {
  left: 54px !important;
  bottom: 12px !important;
}