@import '../../assets/css/def/colors.def';

bi-select,
bi-simple-select {
  display: inline-flex;

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  bi-dropdown {
    &.bs-custom-toggle {
      bi-toggle {
        i {
          position: unset;
        }
      }
    }

    bi-toggle {
      position: relative;

      i {
        position: absolute;
        right: 5px;
        top: 6px;
        cursor: pointer;
      }

      .bi-input {
        padding-right: 30px;
        cursor: pointer;
      }
    }

    .bd-content {
      [ng-transclude=''] {
        .bi-dropdown-menu {
          width: 100%;
          line-height: initial !important;
          font-size: 13px !important;
          border-top: 0;
        }
      }
    }
  }
}
