@import '../../lib/ui/assets/css/def/colors.def';
@import '../../lib/ui/assets/css/def/state.def';
@import '../../lib/ui/assets/css/def/border.def';

quix-header {
  height: 100%;
  margin-left: 10%;

  .quix-header-menu {
    .bi-action {
      @include hover('lighter', $primary);
      @include active('lighter', $primary);


      font-size: 14px;
      border-radius: 0;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      background-color: transparent !important;

      &:hover,
      &.bi-active {
        border-bottom-color: currentColor;
      }
    }
  }

  bi-search.bi-search--rnd {
    position: fixed;
    width: 400px;
    top: 8px;
    left: 50%;
    margin-left: -200px;

    .bi-input {
      height: 38px !important;
    }
  }
}
