@import 'def/state.def';

// default
.bi-muted {
  @include muted('lighter');
}

.bi-active {
  @include active('lighter');
}

.bi-hover {
  @include hover('lighter');
}

.bi-fade {
  @include fade('lighter');
}

.bi-elevate {
  @include elevate();
}

// dark
.bi-theme--dark {
  .bi-muted {
    @include muted('dark');
  }
  
  .bi-active {
    @include active('dark');
  }
  
  .bi-hover {
    @include hover('dark');
  }
}

//  darker
.bi-theme--darker {
  .bi-muted {
    @include muted('darker');
  }
  
  .bi-active {
    @include active('darker');
  }
  
  .bi-hover {
    @include hover('darker');
  }
}

// lighter
.bi-theme--lighter {
  .bi-muted {
    @include muted('lighter');
  }
  
  .bi-active {
    @include active('lighter');
  }
  
  .bi-hover {
    @include hover('lighter');
  }
}

// light
.bi-theme--light {
  .bi-muted {
    @include muted('light');
  }
  
  .bi-active {
    @include active('light');
  }
  
  .bi-hover {
    @include hover('light');
  }

  .bi-theme--lighter {
    .bi-muted {
      @include muted('lighter');
    }
    
    .bi-active {
      @include active('lighter');
    }
    
    .bi-hover {
      @include hover('lighter');
    }
  }
}
