@import '../../../ui/assets/css/def/colors.def';

bi-app-login {
  img {
    width: 100px;
    margin-bottom: 43px;
  }

  .bi-panel {
    width: 400px;

    button {
      height: 40px;
    }
  }
}
